export const DEPLOY_USA = "DEPLOY_USA";
export const DEPLOY_RUSSIA = "DEPLOY_RUSSIA";
export const LOGIN = "Login";
export const PASSWORD = "Password";
export const ENTER = "Enter";
export const SEARCH_TREATMENT_BY_PATIENT_ID =
  "TYPE A PATIENT ID TO SEE TREATMENTS";
export const PATIENT = "Patient";
export const VERSION = "Version";
export const MODIFICATION = "Modification";
export const NO = "No";
export const YES = "Yes";
export const LOADING = "Loading...";
export const FILE_UPLOADING = "File uploading...";
export const RUNNING = "Running...";
export const PATIENT_ID = "Patient ID";
export const TREATMENT_ID = "Treatment ID";
export const TREATMENT_TYPE = "Treatment type";
export const WORKFLOW_TYPE = "Workflow type";
export const PUBLISHED = "Published";
export const CREATION_DATE = "Created";
export const MODIFIED = "Modified";
export const TECHNICIAN = "Technician";
export const DOCTOR = "Doctor";
export const AUTHOR = "Author";
export const STATUS = "Status";
export const CURRENT_STEP = "Current step";
export const RUN = "Run";
export const RUN_SUBMIT = "Submit and Run";
export const SEGMENTATION_STARTED =
  "Workflow has been started! Follow the status";
export const FILES = "FILES";
export const DROP_SELECT_FILE =
  "Drag 'n' drop a file here, or click to select a file";
export const DROP_SELECT_FILE_AGAIN = "Please, upload a file again";
export const UPLOAD_FILES = "Please, upload archive which contains DICOM files";
export const UPLOAD_FILE_TYPE = (label) => `Please, upload a ${label} file`;
export const FILE_UPLOADED =
  "File has been uploaded! You will be automatically redirect to the treatment page";
export const FILE_REUPLOADED = "File has been reuploaded!";
export const TREATMENT_CREATED =
  "Treatment has been created! Please, wait until the file will be uploaded.";
export const FILE_WAS_REMOVED = "File was removed";

export const COPY_TREATMENT_ID = "Click to copy Treatment ID";
export const COPY_FILE_ID = "Click to copy File ID";

export const UPLOAD = "Upload";
export const UPLOAD_FILE_AFTER_CREATE_TREATMENT = "Upload file";
export const UPLOAD_FILE_AFTER_CREATE_TREATMENT_AND_RUN = "Upload and Run";
export const DOWNLOAD = "Download";
export const REUPLOAD = "Reupload";
export const SUBMIT = "Submit";
export const NOTHING_FOUND = "Nothing found!";

export const DRAFT = "Draft";
export const IN_PROGRESS = "In progress";
export const FAILED = "Failed";
export const DONE = "Done";

export const CT_FLOW = "CT Flow";

export const TREATMENT_PLAN = "Treatment plan";
export const CORRECTION_I = "Correction 1";
export const CORRECTION_II = "Correction 2";
export const CORRECTION_III = "Correction 3";
export const EXTRA_CORRECTION = "Extra correction";

export const REGION = "Region";
export const SMILE = "3D Smile";
export const PREDICT = "3D Predict";

export const SWITCH_TO_VERSION = 'Switch';
export const APPROVED_0 = 'Approved';
export const CURRENT = 'Current';
export const BUTTON_TO_MODELER = 'Go to modeler';
export const PLAN_ID = 'Plan ID';
export const PARENT_PLAN = 'Parent plan';
export const APPROVE_DATE = 'Approval Date in Portal';
export const PUBLICATION_DATE = 'Publication Date in Portal';
export const DOCTORS_COMMENT = 'Doctor’s Comment';
export const CROWN_CASE_ID = 'Patient’s ID';
export const NO_COMMENT = 'No comment'

export const TREATMENT_TYPES = {
  "Treatment plan": TREATMENT_PLAN,
  Correction: CORRECTION_I,
  "Correction 2": CORRECTION_II,
  "Correction 3": CORRECTION_III,
  "Extra correction": EXTRA_CORRECTION,
};
export const WORKFLOW_TYPES = {
  "CT Flow": CT_FLOW,
};
export const REGIONS = {
  "3D Smile": SMILE,
  "3D Predict": PREDICT,
};
export const STATUSES = {
  draft: DRAFT,
  in_progress: IN_PROGRESS,
  failed: FAILED,
  done: DONE,
};
